.List {
  list-style: none;
  margin: 0;
  padding: 2rem;
}

.List:empty:after {
  content: attr(data-placeholder);
  font-size: 1.7rem;
  font-weight: bold;
  font-family: var(--font-alt);
  text-align: center;
  color: var(--gray);
}

.Item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0 1rem;
}

.Title {
  flex: 0 50%;
  font-weight: bold;
}

.Button {
  composes: Button from "../index.css";
  --background-color: var(--red);
  flex: 100%;
}

@media screen and (min-width: 30rem) {
  .Button {
    flex: 0;
  }
}
