@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu+Mono:wght@700&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --black: hsl(1, 4%, 20%);
  --white: hsl(1, 4%, 96%);
  --gray: hsl(1, 2%, 69%);
  --gray-light: hsl(1, 2%, 82%);
  --green: hsl(158, 42%, 50%);
  --yellow: hsl(53, 74%, 69%);
  --red: hsl(3, 74%, 69%);
  --font: "Roboto", sans-serif;
  --font-alt: "Ubuntu Mono", monospace;
  --container-size: 75rem;
  --border-radius: 3px;
  --button-shadow: 0 4px 6px hsla(240, 30%, 28%, 0.3),
    0 1px 3px hsla(0, 0%, 0%, 0.4);
}

body {
  margin: 0;
}

.Container {
  max-width: var(--container-size);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  position: absolute;
  overflow: hidden;
  width: 0px;
  height: 0px;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
}

.Input,
.Button {
  font-size: 1rem;
  font-family: var(--font);
}

.Button {
  --background-color: var(--black);
  --color: var(--white);
  text-transform: uppercase;
  border-radius: var(--border-radius);
  background: var(--background-color);
  color: var(--color);
  padding: 0.5rem 1rem;
  font-family: var(--font-alt);
  font-weight: bold;
  letter-spacing: 1px;
  border: none;
  box-shadow: var(--button-shadow);
}

.Button:disabled {
  --background-color: var(--gray);
  --button-shadow: none;
}

.Input {
  --background-color: var(--gray-light);
  width: 100%;
  background: var(--background-color);
  color: var(--color);
  padding: 0.5rem;
  border: none;
  border-radius: var(--border-radius);
  outline: none;
}

.Input:focus {
  --background-color: var(--black);
  --color: var(--white);
}
