.Wrapper {
  --form-width: 40rem;
  background: var(--white);
  color: var(--black);
  font-family: var(--font);
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-flow: column;
}

.Wrapper[data-nightmode="true"] {
  --white: hsl(1, 4%, 20%);
  --yellow: hsl(53, 51%, 43%);
  --black: hsl(1, 4%, 96%);
}

.Header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.Title {
  font-family: var(--font-alt);
  font-size: 1.4rem;
  margin: 0 0 1rem;
  color: var(--green);
}

.Title:after {
  content: attr(data-subtitle);
  margin-left: 1ch;
  font-family: var(--font);
  font-weight: normal;
  font-size: 1rem;
  color: var(--gray);
}

@media screen and (min-width: 70rem) {
  .Content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .Content ol {
    flex: 1;
  }

  .Content form {
    flex: 0 var(--form-width);
    order: 1;
  }
}
