.Form {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
}

.Form label {
  display: flex;
  flex-flow: column;
  margin-bottom: 2rem;
}

.Form span {
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  padding-bottom: 0.25rem;
}

.Controls {
  display: flex;
  justify-content: flex-end;
}

.Controls > * {
  margin-left: 1rem;
}

.Controls > *:first-child {
  margin-left: 0;
}

.Save {
  composes: Button from "../index.css";
  --background-color: var(--green);
}

.ExpiresOn,
.RemindOn,
.Info,
.Controls {
  flex: 100%;
}

.Errors {
  color: var(--red);
  font-weight: normal;
  padding: 0.2em;
}

@media screen and (min-width: 30rem) {
  .ExpiresOn,
  .RemindOn {
    --space: 2rem;
    flex: calc(50% - var(--space));
  }

  .ExpiresOn {
    margin-right: var(--space);
  }
}
